import React from "react"
import {Link} from "gatsby"

const Home = () => {
  return (
    <div>
        <div className="slogan">Scientific imaging across scales and tools</div>
        <div className="scales">
            <div className="scales-img"></div>
        </div>
        <div className="flex">
            <div className="image-play"></div>
            <div className="border">
                <pre><code>
$ pip install git+https://gitlab.com/album-app/album/<br/>
$ album add-catalog https://solutions.capture-knowledge.org<br/>
$ album install ida-mdc:fiji-app:0.1.0<br/>
$ album run ida-mdc:fiji-app:0.1.0</code></pre>
            </div>
        </div>
        <div className="flex">
            <Link to="/catalog" className="image-link link-catalog"><b>GO TO THE CATALOG</b><br/>.. to find specific solutions.</Link>
            <Link to="https://album.solutions" className="image-link link-documentation"><b>GO TO THE ALBUM MANUAL</b><br/>.. for usage documentation.</Link>
        </div>
    </div>
  )
}

export default Home
